<template>
  <button
    :class="[
      'radioButton',
      isActive && 'radioButton--selected',
      disabled && 'radioButton--disabled',
      isRow && 'radioButton--row',
      hideCircle && 'radioButton--circle-hidden',
      isSmall && 'radioButton--small'
    ]"
    :disabled="disabled"
    @click.prevent="!isActive ? $emit('event') : () => null"
  >
    <slot />
    <span v-if="!hideCircle" class="radioButton__circle"> </span>
  </button>
</template>

<script>
export default {
  props: {
    isActive: Boolean,
    disabled: Boolean,
    isRow: Boolean,
    hideCircle: Boolean,
    isSmall: Boolean
  },

  emits: ['event']
}
</script>

<style lang="scss">
.radioButton {
  position: relative;
  display: flex;
  align-items: center;
  margin-bottom: rem(8);
  padding: rem(16) rem(48) rem(16) rem(16);
  width: 100%;
  min-height: 60px;
  border: 1px solid $grey--light;
  border-radius: 4px;
  font-family: $mb-font-text;
  font-size: rem(16);
  text-align: left;
  color: $black--light;
  cursor: pointer;
  transition: 0.2s linear;
  -webkit-tap-highlight-color: transparent;

  &--selected {
    border: 1px solid $blue;
    cursor: default;

    .radioButton__circle {
      &::after {
        transform: scale(1);
        opacity: 1;
        visibility: visible;
      }
    }
  }
  &--disabled {
    opacity: 0.3;
    cursor: unset;
  }
  &--row {
    display: block;
  }
  &--circle-hidden {
    padding-right: rem(16);
    margin-bottom: rem(4);
    &.radioButton--selected {
      border-width: 1px;
      box-shadow: $blue 0px 0px 2px;
    }
  }
  &--small {
    padding: rem(16) rem(32) rem(16) rem(16);

    .radioButton__circle {
      width: 12px;
      height: 12px;

      &::after {
        width: 8px;
        height: 8px;
        top: -1px;
        left: -1px;
      }
    }
  }
}

.radioButton__circle {
  position: absolute;
  top: 50%;
  right: rem(16);
  transform: translateY(-50%);
  padding: rem(4);
  border: 1px solid $grey--dark;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  box-shadow: 0 0 3px 1px rgba(33, 33, 33, 0.2) inset;

  &::after {
    content: '';
    position: absolute;
    left: 1px;
    top: 1px;
    margin: rem(2);
    width: calc(100% - 0.4rem);
    height: calc(100% - 0.4rem);
    border-radius: 20px;
    background: $blue;
    opacity: 0;
    visibility: hidden;
    transform: scale(0.7);
    transition: 0.3s $cubicEasing;
  }
}
</style>
